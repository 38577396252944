.header-landing {
  background-position: 85% 0%;
}

.header-beneficios {
  background-position: 100% 0%;
}

.wavesShadow {
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.35));
  max-height: 120px;
}

@media (min-width: 640px) {
  .header-landing {
    background-position: center;
  }
}
.react-multi-carousel-dot--active button {
  background-color: gray;
}
