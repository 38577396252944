.burger {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;

  background: #cdcdcd;
  border-radius: 3px;

  transform-origin: 4px 0px;

  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

.fstBar {
  transform-origin: 0% 0%;
}

.sndBar {
  transform-origin: 0% 100%;
}

.trdBar {
  transform-origin: 0% 0%;
  margin-bottom: 0px;
}

/* 
 * Transform all the slices of hamburger
 * into a crossmark.
 */
.fstOpen {
  opacity: 1;
  transform: translate(5px, -2px) rotate(45deg);
  /* transform: rotate(45deg) translate(-2px, -1px); */
  background-color: white;
}

/*
 * But let's hide the middle one.
 */
.sndOpen {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
 * Ohyeah and the last one should go the other direction
 */
.trdOpen {
  opacity: 1;
  /* transform: translate(-4px, 0px) rotate(-45deg); */
  transform: translate(2.3px, 3.3px) rotate(-45deg);
  background-color: white;
}

.sideDrawer {
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 200;
  box-shadow: -1px 0px 9px rgba(0, 0, 0, 0.5);
  /* box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1); */
  transform: translateX(150%);
  /* transition: transform 0.3s ease-out; */
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

.sideDrawerOpen {
  transform: translateX(0%);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}
