.watermark {
  display: none !important;
}

.leftwatermark {
  display: none !important;
}

.header-beneficios {
  background-position: 50% 25%;
  height: 15rem;
}
@media (min-width: 640px) {
  .header-beneficios {
    background-position: 25% 45%;
    height: 22rem;
  }
}

.header-login {
  background-position: 0% 75%;
  height: 12rem;
}
@media (min-width: 768px) {
  .header-login {
    background-position: 100% 70%;
    height: 18rem;
  }
}
@media (min-width: 1280px) {
  .header-login {
    background-position: 100% 65%;
    height: 22rem;
  }
}

.header-preguntas {
  background-position: 0% 0%;
  background-size: contain;
  height: 12rem;
}
@media (min-width: 768px) {
  .header-preguntas {
    background-position: center;
    background-size: cover;
    height: 18rem;
  }
}
@media (min-width: 1280px) {
  .header-preguntas {
    background-position: 50% 15%;
    background-size: cover;
    height: 18rem;
  }
}

.wavesShadow {
  filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.2));
  max-height: 120px;
}

.higiaRadio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border-radius: 50%;
  width: 16px;
  height: 16px;

  background-color: #c4c4c4;
  transition: 0.2s all linear;
  margin-right: 5px;

  position: relative;
  top: 4px;

  outline: 0;
}

.higiaRadio:checked {
  border: 6px solid #c4c4c4;
  background-color: white;
}

.pwaModal {
  left: calc(50% - 10rem);
  top: calc(50% - 7.5rem);
  height: 15rem;
  width: 20rem;
}

/* Flicking home */
.eg-flick-ses-viewport {
  border-radius: 0.5rem;
  height: 10rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #fff0da;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.eg-flick-apuntes-viewport {
  border-radius: 0.5rem;
  height: 10rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #f6f6f6;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.eg-flick-escuchar-viewport {
  border-radius: 0.5rem;
  height: 10rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
