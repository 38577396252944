.container {
  width: fit-content;
}
.steps {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 200px;
}
.step {
  width: 25px;
  height: 25px;
  background: #acaca6;
  border: 2px solid #acaca6;
  border-radius: 50%;
  transition: background 1s;
}
.step.selected {
  border: 2px solid #85cec1;
  background: #85cec1;
}
.step.completed {
  border: 2px solid #85cec1;
  background: #85cec1;
}
.progress {
  position: relative;
  top: 15px;
  width: 100%;
  height: 50%;
  border-bottom: 5px solid #acaca6;
  z-index: -1;
}
.percent {
  position: absolute;
  width: 0;
  height: 100%;
  border-bottom: 5px solid #85cec1;
  z-index: 1;
  transition: width 1s;
}
